export const promotionTypes = [
  {
    text: "Discount",
    value: "discount",
  },
  // { TODO: Do not remove  - should only be disable for a couple of sprints
  //   text: "Promo Code",
  //   value: "promocode",
  // },
];

export const salesChannels = [
  {
    text: "Express app",
    value: "express_app",
  },
  {
    text: "POS",
    value: "pos",
  },
];

export const promotionStatues =
  [
    {
      text: 'Draft',
      value: 'draft'
    },
    {
      text: 'Ongoing',
      value: 'ongoing'
    },
    {
      text: 'Pending',
      value: 'pending'
    },
    {
      text: 'Paused',
      value: 'paused'
    },
    {
      text: 'Canceled',
      value: 'canceled'
    },
    {
      text: 'Expired',
      value: 'expired'
    },
    {
      text: 'Done',
      value: 'done'
    }
  ]
